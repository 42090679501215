<template>
    <div class="input">
        <div class="_header" v-if="name">
            <label class="label">{{ name || 'Label' }}</label>
            <span class="_error">{{ error }}</span>
        </div>
        <input class="input-field" :class="[{ 'error' : inputError }]" autocomplete="none" :type="type || 'text'" :name="inputName" v-model="content"  :placeholder="placeholder" @input="$emit('valueChanged')" @focus="$emit('focus')" @blur="$emit('blur')" :disabled="disabled">
        <div class="description" v-if="description">{{ description }}</div>
    </div>
</template>

<script>
    export default {
        props: ['name', 'modelValue', 'type', 'placeholder', 'error', 'inputError', 'disabled', 'inputName', 'description'],
        data() {
            return {
                content: this.modelValue || this.value || ''
            }
        },
        mounted() {
            if(this.modelValue != null) {
                try {
                    this.content = String(this.modelValue);
                } catch(e) {}
            }
        },
        watch: {
            content(newValue, oldValue) {
                this.$emit('update:modelValue', this.content);
            },
            modelValue() {
                this.content = this.modelValue;
            }
        },
    }
</script>

<style lang="scss">

.input {
    display: grid;
    row-gap: 5px;
    width: 100%;
    ._header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        label {
            font-weight: 500;
            font-size: 13px;
        }
        ._error {
            color: $error;
            font-size: 11px;
        }
    }
    .description {
        color: $textShade;
        font-size: 11px;
    }
    .input-field {
        width: 100%;
        height: 38px;
        border-radius: 7px;
        outline: none;
        padding: 0 10px;
        border: 1px solid $borderColor;
        background: $themeColor1;
        color: $text;
        font-size: 16px;
        font-size: 13px;
        box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.02);
        &.error {
            border: 1px solid $error;
        }
        &:after {
            content: none !important;
        }
    }
}
</style>
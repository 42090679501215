<template>
    <button :type="type || 'button'" :class="[theme, { 'icon' : icon }, size]"  :disabled="disabled">
        <span v-if="!$slots.default">{{ text }}</span>
        <div v-else><slot></slot></div>
        <div v-if="icon" class="icon">
            <span v-if="processing" class="processing"><i class="fa-solid fa-spinner"></i></span>
            <span v-else><i :class="icon"></i></span>
        </div>
    </button>
</template>

<script>
    export default {
        props: ['text', 'icon', 'type', 'size', 'disabled', 'processing', 'theme']
    }
</script>

<style lang="scss" scoped>
button {
    text-decoration: none;
    display: flex;
    height: 44px;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    column-gap: 20px;
    background: $themeColor2; 
    border: 0;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: ease 0.3s;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid $themeColor2-shade;
    &.icon {
        padding: 5px 5px 5px 20px;
    }
    .icon {
        width: 34px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $themeColor4;
        color: #fff;
        border-radius: 10px;
    }
    
    &.small {
        height: 34px;
        padding: 5px 10px;
        font-size: 12px;
        border-radius: 7px;
        &.icon {
            padding: 5px 5px 5px 10px;
        }
        .icon {
            width: 24px;
            height: 24px;
            border-radius: 7px;
        }
    }

    &:disabled {
        opacity: 0.65;
        cursor: not-allowed;
    }

    &.white {
        border: 1px solid $borderColor;
        box-shadow: $boxShadow;
        color: $textShade;
        background: $themeColor1;
    }

    &.error {
        border: 1px solid $error;
        box-shadow: $boxShadow;
        color: #fff;
        background: $error;
    }

}

.processing {
    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}
</style>
<template>
    <div class="action-bar">
        <h3>{{ title }}</h3>
        <div class="action"><slot></slot></div>
    </div>
</template>

<script>
    export default {
        props: ['title']
    }
</script>

<style lang="scss" scoped>
.action-bar {
    display: flex;
    align-items: center;
    gap: 30px;
}
</style>
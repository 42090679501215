import {
  createVNode,
  defineComponent,
  markRaw,
  render,
  shallowReactive,
} from "vue/dist/vue.esm-bundler";
import { shallowRef } from "vue";
import { v4 as uuidv4 } from "uuid";

import ModalManager from "./ModalManager";
import CAlert from "./CAlert";

class Modal {
  constructor(options) {
    this.title = options.title || null;
    this.description = options.description;
    this.buttons = {
      confirm: options.confirm || "Confirm",
      cancel: options.cancel || "Cancel",
    };
    this.width = options.width || null;
    this.props = options.props || {};
    if (options.hideCancelButton) this.hideCancelButton = true;
    else this.hideCancelButton = false;
    this.component = options.component ? markRaw(options.component) : null;
    this.callback = {
      close: [],
    };

    this.preventFromClosing = options.preventFromClosing || false;

    if (options.extraLink) {
      this.extraLink = { text: "Link name", callback: null };
      if (options.extraLink.text) {
        this.extraLink.text = options.extraLink.text;
      }
      if (options.extraLink.callback) {
        this.extraLink.callback = options.extraLink.callback;
      }
    }

    this.modalId = uuidv4();
    return this;
  }

  processing(value) {
    this.isProcessing = value;
    this.RunNextTick();
  }

  setProp(name, value) {
    this.props[name] = value;
    this.RunNextTick();
  }

  onReopen(callback) {
    this.callback.reopen = callback;
  }

  reopened() {
    if (this.callback.reopen && typeof this.callback.reopen === "function") {
      this.callback.reopen();
    }
  }

  onConfirm(callback) {
    this.callback.confirm = callback;
  }

  onCancel(callback) {
    this.callback.cancel = callback;
  }

  onClose(callback) {
    this.callback.close.push(callback);
  }

  cancel() {
    if (this.callback.cancel) this.callback.cancel();
    this.close();
  }

  close() {
    this.closed = true;
    this.RunNextTick();
    for (const callback of this.callback.close) {
      callback();
    }
  }

  nextTick(callback) {
    this.RunNextTick = callback;
  }

  extraLinkCallback() {}
}

export default {
  install: (app, options) => {
    let component = {
      components: {
        ModalManager,
      },
      data() {
        return {
          modals: [],
        };
      },
      methods: {
        createModal(options = null) {
          if (!options) return null;
          let modal = new Modal(options);
          this.modals.push(modal);

          modal.nextTick(() => {
            this.$forceUpdate();
          });

          modal.onClose(() => {
            for (let i = 0; i < this.modals.length; i++) {
              if (this.modals[i].modalId === modal.modalId) {
                setTimeout(() => {
                  this.modals.splice(i, 1);
                }, 100);
                break;
              }
            }
          });

          return modal;
        },
        createAlert(text) {
          let modal = this.createModal({
            component: CAlert,
            confirm: "Ok",
            width: "350px",
            hideCancelButton: true,
            props: {
              text: text,
            },
          });

          modal.onConfirm(() => {
            modal.close();
          });

          return modal;
        },
        async createConfirm(text) {
          return await new Promise((resolve) => {
            let modal = this.createModal({
              component: CAlert,
              confirm: "Confirm",
              width: "350px",
              props: {
                text: text,
              },
            });

            modal.onConfirm(() => {
              modal.close();
              resolve(true);
            });

            modal.onCancel(() => {
              resolve(false);
            });
          });
        },
      },
      mounted() {
        app.config.globalProperties.$ShowAlert = async (text) => {
          this.createAlert(text);
        };

        app.config.globalProperties.$ShowConfirm = async (text) => {
          return this.createConfirm(text);
        };

        app.config.globalProperties.$ShowModal = (options = null) => {
          return this.createModal(options);
        };
      },
      template: `<ModalManager v-model="modals"/>`,
    };

    app.component("ModalManager", component);
  },
};

import { mapGetters } from "vuex";

export default {
  data() {
    return {
      APP_MONTHS: [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december",
      ],
    };
  },
  computed: {
    CURRENCY() {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
      });
      return formatter;
    },
    PERCENTAGE() {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "percent",
        minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
      });
      return formatter;
    },
  },
  methods: {
    CONVERT_PRICE(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    COPY_TEXT(text) {
      if (!navigator.clipboard) {
        this.FALLBACK_COPY_TEXT(text);
        return;
      }
      navigator.clipboard.writeText(text).then(
        function () {},
        function (err) {}
      );
    },
    FALLBACK_COPY_TEXT(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
      } catch (err) {}

      document.body.removeChild(textArea);
    },
  },
};

<template>
    <div>
        
        <div class="wrapper">
            <form class="login-form" action="" method="POST" @submit.prevent="handleSubmit">
                <div class="grid gap-20">
                    <h1>Log in</h1>
                    <Input name="Username" v-model="username" :error="errors.username" placeholder="Username"/>
                    <Input name="Password" type="password" :error="errors.password" v-model="password" placeholder="Password"/>
                    <Button text="Submit" type="submit" @click="handleSubmit"/>
                    <div style="display: flex; justify-content: center;">
                        <a href="" @click.prevent="$emit('skipLogin')">Skip</a>
                    </div>
                </div>
            </form>
        </div>
        
    </div>
</template>

<script>
import Auth from '../mixins/Auth'

    export default {
        mixins: [Auth],
        data() {
            return {
                username: '',
                password: '',
                errors: {}
            }
        },
        methods: {
            handleSubmit() {
                this.errors = {};
                this.admin_auth_login(this.username, this.password, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else alert(body.message || `Something went wrong. Please, try again!`)
                    } else {

                        if(body.token) {
                            // store token in app store
                            this.$store.commit('adminToken', body.token);
                            localStorage.setItem("ADMIN_TOKEN", body.token);
                        } else {
                            alert("Something went wrong. Please, try again!")
                        }
                    }
                    
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
.wrapper {
    min-height: 100vh;
    padding: 100px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form {
    max-width: 440px;
    width: 100%;
    background: $bgShade;
    padding: 20px;
    border-radius: 20px;
}
</style>
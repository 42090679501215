<template>
  <div>
    <div class="ModalManagerBG" :class="{ active: modals.length > 0 }"></div>

    <div :key="modal.modalId" v-for="(modal, key) of modals">
      <div class="modal-group">
        <div class="modal-scroll-content">
          <div
            class="modal-window-holder"
            :data-modal-holder="true"
            @click="(e) => closeCurrentModal(e, modal)"
          >
            <div
              class="modal-content bounce-enter-active"
              :data-modal="modal.modalId"
              :style="[modal.width ? `max-width: ${modal.width}` : '']"
              :class="{ hidden: currentModal !== key || modal.closed }"
            >
              <div class="header" v-if="modal.title">
                <h3 class="text">{{ modal.title }}</h3>
                <div class="subtext" v-if="modal.description">
                  {{ modal.description }}
                </div>
                <button class="close-btn" @click="closeModal(key)">
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>

              <div class="modal-user-content">
                <component
                  :is="modal.component"
                  v-bind="modal.props"
                  :modal="modal"
                />
                <div class="loader" v-if="modal.isProcessing"></div>
              </div>

              <div class="modal-action">
                <Button
                  v-if="!modal.hideCancelButton"
                  :text="modal.buttons.cancel"
                  size="small"
                  class="cancel-btn"
                  @click="closeModal(key)"
                  :disabled="modal.isProcessing"
                />
                <Button
                  :text="modal.buttons.confirm"
                  size="small"
                  @click="confirm(modal, key)"
                  :disabled="modal.isProcessing"
                  :processing="modal.isProcessing"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["modelValue"],
  data() {
    return {
      modals: this.modelValue || [],
    };
  },
  computed: {
    currentModal() {
      return this.modals.length - 1;
    },
    modalsQuantity() {
      return this.modals.length;
    },
  },
  methods: {
    closeModal(key) {
      this.modals[key].cancel();
    },
    closeCurrentModal(e, modal) {
      try {
        if (e.target.hasAttribute("data-modal-holder")) {
          if (!modal.preventFromClosing) this.closeModal(this.currentModal);
        }
      } catch (e) {}
    },
    async confirm(modal, key) {
      if (modal.callback.confirm) {
        modal.callback.confirm(modal.props);
      }
    },
  },
  watch: {
    currentModal(to, from) {
      if (to >= 0 && to < from) {
        if (this.modals[this.currentModal]) {
          this.modals[this.currentModal].reopened();
        }
      }
    },
    modelValue() {
      this.modals = this.modelValue || [];
    },
    modals() {
      this.$emit("update:modelValue", this.modals);
    },
    modalsQuantity() {
      if (this.modalsQuantity > 0) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes bounce-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.ModalManagerBG {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  pointer-events: none;
  opacity: 0;
  transition: ease 0.3s;
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}

.modal-group {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  .modal-scroll-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .modal-window-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    padding: 50px 20px;
  }
  .modal-user-content {
    position: relative;
    .loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      z-index: 3;
    }
  }
  .modal-content {
    background: $themeColor1;
    min-width: 250px;
    max-width: 500px;
    width: 100%;
    border-radius: 13px;
    min-height: 100px;
    box-shadow: $boxShadow;
    transform: translateY(0) scale(1);
    opacity: 1;
    pointer-events: auto;
    transition: ease 0.1s;

    &.hidden {
      transform: scale(0.5);
      opacity: 0;
      pointer-events: none;
    }

    .header {
      padding: 20px;
      border-bottom: 1px solid $borderColor;
      position: relative;
      .subtext {
        opacity: 0.75;
        font-size: 12px;
      }
      .close-btn {
        position: absolute;
        top: 20px;
        right: 20px;
        border: 0;
        padding: 0;
        background: transparent;
        color: $error;
        cursor: pointer;
        font-size: 14px;
        width: 28px;
        height: 28px;
        border-radius: 9px;
        box-shadow: $boxShadow;
        background: $themeColor1;
        border: 1px solid $borderColor;
        transition: ease 0.3s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    .modal-action {
      border-top: 1px solid $borderColor;
      padding: 20px;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      .cancel-btn {
        border: 1px solid $borderColor;
        background: transparent;
        color: $text;
      }
    }
  }
}
</style>

import { createStore } from 'vuex'

export default createStore({
    state: {
        axiosLoaders: [],
        admin: {
            token: null
        },
    },
    getters: {
        axiosLoaders: (state) => {
            return state.axiosLoaders;
        },
        admin: (state) => {
            return state.admin;
        },
        adminToken: (state) => {
            return state.admin && state.admin.token ? state.admin.token : null;
        },
    },
    mutations: {
        adminToken: (state, payload) => {
            state.admin.token = payload;
        }
    },
    actions: {

    },
    modules: {
        
    }
})

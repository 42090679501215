import axios from 'axios';


axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(function (config) {
    let adminToken = localStorage.getItem('ADMIN_TOKEN');
    if(adminToken) {
        config.headers['admin-authorization'] = 'Bearer ' + adminToken;
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './axios'

let app = createApp(App);


import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, far, fab)
dom.watch();

import MonthPicker from './components/MonthPicker/MonthPicker'
app.component('MonthPicker', MonthPicker);


import VueApexCharts from "vue3-apexcharts";
app.component('ApexChart', VueApexCharts);

// v-calendar
import 'v-calendar/dist/style.css';
import { SetupCalendar, DatePicker } from 'v-calendar';
app.use(SetupCalendar, {})
app.component('DatePicker', DatePicker);




// global components
import Button from './components/Button'
app.component('Button', Button);


import Spinner from './components/Spinner'
app.component('Spinner', Spinner);

import Input from './components/Input'
app.component('Input', Input);

import ActionBar from './components/ActionBar'
app.component('ActionBar', ActionBar);

import TableView from './components/TableView'
app.component('Table', TableView);

import TableViewRow from './components/TableViewRow'
app.component('TableRow', TableViewRow);

import DropDownList from './components/DropDownList'
app.component('DropDownList', DropDownList);

import DropDownMenuButton from './components/DropDownMenuButton'
app.component('DropDownMenuButton', DropDownMenuButton);

import DropDownDatePicker from './components/DropDownDatePicker.vue'
app.component('DropDownDatePicker', DropDownDatePicker);

import DropDownMonthPicker from './components/DropDownMonthPicker.vue'
app.component('DropDownMonthPicker', DropDownMonthPicker);

import Checkbox from './components/Checkbox'
app.component('Checkbox', Checkbox);

import DropDownCheckbox from './components/DropDownCheckbox'
app.component('DropDownCheckbox', DropDownCheckbox);

import NoDataMessage from './components/NoDataMessage'
app.component('NoDataMessage', NoDataMessage);

import Switch from './components/Switch'
app.component('Switch', Switch);

// mixins
import AxiosLoader from './mixins/AxiosLoader'
app.mixin(AxiosLoader);

import Vars from './vars'
app.mixin(Vars);


import vClickOutside from "click-outside-vue3"
app.use(vClickOutside);

import ModalManager from './plugins/ModalManager';
app.use(ModalManager);

import AppWebSocket from './plugins/AppWebSocket/index';
app.use(AppWebSocket);


app.use(store).use(router).mount('#app')


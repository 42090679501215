<template>
  <div
    class="dropdown-select"
    @click="switchView"
    v-click-outside="hideView"
    :class="{ active: shown }"
  >
    <div class="selection text-overflow">{{ selected }}</div>
    <div class="icon-holder" v-if="!disabled">
      <span v-if="shown"><i class="fa-solid fa-chevron-up"></i></span>
      <span v-else><i class="fa-solid fa-chevron-down"></i></span>
    </div>
    <div
      class="list-window-holder"
      v-if="!disabled"
      :class="[`position-${position || 'left'}`, shown ? 'active' : '']"
      @click.stop
    >
      <div
        class="list-window"
        :class="[shown ? 'active' : loaded ? 'hidden' : '']"
      >
        <div class="list-input-holder">
          <input
            type="text"
            class="list-search"
            v-model="search"
            placeholder="Search"
          />
        </div>

        <div class="list-scroll">
          <div class="list-content">
            <div class="list" v-if="searchedUserOptions.length > 0 && !loading">
              <div
                class="option"
                :key="key"
                v-for="(opt, key) of searchedUserOptions"
                :class="[{ active: selection === opt[selectAttribute] }]"
                @click="selectOption(opt)"
              >
                <div class="name text-overflow">{{ _showAttribute(opt) }}</div>
                <span v-if="selection === opt[selectAttribute]"
                  ><i class="fa-solid fa-check"></i
                ></span>
              </div>
            </div>

            <div class="list" v-else>
              <div class="loader" v-if="loading"><Spinner size="small" /></div>
              <NoDataMessage
                style="padding: 20px 0"
                :text="'No data to display'"
                v-else
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: { type: Array },
    showAttribute: { required: true },
    selectAttribute: { type: String, required: true },
    placeholder: { type: String, required: false },
    modelValue: { required: true },
    position: { type: String },
    dynamicSearch: { type: Boolean },
    loading: { type: Boolean },
    disabled: { type: Boolean },
  },
  data() {
    return {
      shown: false,
      selection: this.modelValue || null,
      search: "",
      loaded: false,
    };
  },
  mounted() {},
  methods: {
    _showAttribute(option) {
      if (typeof this.showAttribute === "string") {
        return option[this.showAttribute];
      } else if (typeof this.showAttribute === "function") {
        return this.showAttribute(option);
      }
      return "Unknown option";
    },
    selectOption(option) {
      this.$emit("select", option);
      this.selection = option[this.selectAttribute];
      this.applyChanges();
      this.hideView();
      this.search = "";
    },
    hideView() {
      this.shown = false;
    },
    switchView() {
      if (this.disabled) return;
      this.shown = !this.shown;
      if (this.shown && !this.loaded) {
        this.loaded = true;
      }
    },
    applyChanges() {
      this.$emit("update:modelValue", this.selection);
    },
    clearSelection() {
      this.selection = null;
      this.applyChanges();
    },
  },
  computed: {
    selected() {
      for (const item of this.userOptions) {
        if (item[this.selectAttribute] === this.selection)
          return this._showAttribute(item);
      }
      return this.placeholder || "Select";
    },
    userOptions() {
      if (!this.options) return [];
      if (!Array.isArray(this.options)) return [];
      return this.options.filter((item) => {
        if (item.hasOwnProperty(this.selectAttribute)) return true;
        return false;
      });
    },
    searchedUserOptions() {
      if (this.dynamicSearch) return this.userOptions;
      let search = this.search.toLowerCase();
      return this.userOptions.filter((item) => {
        let showAttribute = this._showAttribute(item);
        if (typeof showAttribute !== "string") return false;
        let name = showAttribute.toLowerCase();
        if (name.includes(search) || search.includes(name)) return true;
        return false;
      });
    },
  },
  watch: {
    modelValue() {
      this.selection = this.modelValue;
    },
    search() {
      this.$emit("search", this.search);
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes popUpAnimation {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes popUpAnimationReverse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}

.dropdown-select {
  display: grid;
  grid-template-columns: minmax(0, 1fr) 14px;
  column-gap: 15px;
  max-width: 220px;
  align-items: center;
  position: relative;
  padding: 0;
  user-select: none;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    height: 3px;
    width: 100%;
    border-bottom: 2px solid $bgShade;
    transition: ease 0.3s;
  }
  &:hover {
    &::after {
      border-bottom: 2px solid $themeColor2;
    }
  }
  &.active {
    z-index: 100;
    &::after {
      border-bottom: 2px solid $themeColor2;
    }
  }

  .list-search {
    width: 100%;
    height: 44px;
    border-radius: 0;
    border: 0;
    padding: 0 20px;
    font-size: 12px;
    outline: none;
  }

  .icon-holder {
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .list-input-holder {
    border-bottom: 1px solid $borderColor;
  }

  .list-button-holder {
    padding: 20px 20px 10px 20px;
    border-top: 1px solid $borderColor;
    display: grid;
    row-gap: 10px;
    .clear-button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    button {
      width: 100%;
    }
  }

  .list-window-holder {
    position: absolute;
    top: 100%;
    left: 0;
    transform: translateY(20px);
    cursor: auto;
    user-select: text;
    pointer-events: none;
    color: $text;
    &.active {
      pointer-events: auto;
    }
    &.position-center {
      left: 50%;
      transform: translate(-50%, 20px);
      right: auto;
    }
    &.position-right {
      left: auto;
      right: 0;
      transform: translateY(20px);
    }
  }

  .list-window {
    min-width: 220px;
    max-width: 350px;
    background: #fff;
    box-shadow: $boxShadow;
    border-radius: 10px;
    display: grid;
    border: 1px solid $borderColor;
    overflow: hidden;
    opacity: 0;
    transform-origin: 50% 0;
    transform: scale(0);
    pointer-events: none;
    transition: ease 0.1s;
    margin-bottom: 30px;

    &.hidden {
      pointer-events: none;
      animation: popUpAnimationReverse 0.15s;
      animation-fill-mode: forwards;
    }
    &.active {
      pointer-events: auto;
      animation: popUpAnimation 0.2s;
      animation-fill-mode: forwards;
    }
    .list-scroll {
      overflow: auto;
      max-height: 200px;
    }
    .list-content {
      display: grid;
      row-gap: 20px;
      .list {
        padding: 5px;
        display: grid;
        row-gap: 3px;
      }
    }
  }

  .option {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    column-gap: 10px;
    align-items: center;
    padding: 0 15px;
    height: 34px;
    cursor: pointer;
    border-radius: 7px;
    transition: ease 0.3s;
    border: 1px solid transparent;
    user-select: none;
    &:hover,
    &.active {
      color: $themeColor2;
      background: rgba(0, 0, 0, 0.02);
      border: 1px solid $borderColor;
    }
  }
}

.loader {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: function () { return import('../views/Dashboard.vue') }
    },
    {
        path: '/consolidation',
        name: 'Consolidation',
        component: function () { return import('../views/Consolidation.vue') }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router

export default {
    methods: {
        admin_auth_login(username, password, callback) {
            this.ajax('Admin-HandleLogin', {
                method: 'POST',
                url: '/admin/login',
                data: {
                    username: username,
                    password: password
                }
            }, callback)
        },
        admin_is_logged_in(callback) {
            this.ajax('Admin-CheckToken', {
                method: 'GET',
                url: '/admin/isLoggedIn',
            }, callback)
        }
    },
}
<template>
    <div>
        <div class="wrapper logo-holder" v-if="adminToken || skipLogin">
            <img src="@/assets/flexx-freight-logo.png" height="44"/>
            <!-- <button class="settings-btn" v-if="adminToken">
                <span class="text">Settings</span>
                <span class="icon"><i class="fa-solid fa-gear"></i></span>
            </button> -->
        </div>
        <router-view v-if="adminToken || skipLogin"></router-view>
        <Login @skipLogin="() => { skipLogin = true; }" v-else/>
        <ModalManager/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Login from './views/Login.vue';
import Auth from './mixins/Auth';

    export default {
        mixins: [Auth],
        components: {
            Login
        },
        data() {
            return {
                skipLogin: false
            }
        },
        computed: {
            ...mapGetters(['admin', 'adminToken'])
        },
        methods: {
            checkIfLoggedIn() {
                let adminToken = localStorage.getItem('ADMIN_TOKEN');
                if(adminToken) {
                    this.$store.commit('adminToken', adminToken);
                } else {
                    this.$store.commit('adminToken', null);
                    return;
                }

                this.admin_is_logged_in((err, body) => {
                    if(err) {
                        this.$store.commit('adminToken', null);
                        localStorage.removeItem('ADMIN_TOKEN');
                    }
                });
            }
        },
        mounted() {
            this.checkIfLoggedIn();

            this.$WebSocket.setUrl(process.env.VUE_APP_WS_URL);
            this.$WebSocket.connect();
            
            this.$WebSocket.on('open', 'main-component', () => {
                console.log("WebSocket Connected.")
            });

        }
    }
</script>

<style lang="scss">
// scss styles
</style>

<style lang="scss" scoped>
.logo-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.settings-btn {
    border: none;
    border-radius: 9px;
    height: 44px;
    padding: 0 14px;
    background: rgba(0,0,0,0.02);
    display: flex;
    justify-content: space-between;
    gap: 24px;
    align-items: center;
    color: $text;
    cursor: pointer;
    transition: ease 0.3s;
    .text {
        font-weight: 500;
        text-transform: uppercase;
    }
    .icon {
        font-size: 16px;
    }
    &:hover {
        color: $themeColor2;
        background: rgba(0,0,0,0.05);
    }
}
</style>